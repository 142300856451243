import { changeOpacity, viewport } from "./utils";

import videojs from "video.js";
import 'videojs-contrib-quality-levels';
import 'video.js/dist/video-js.css';

const isMobile = viewport().width < 768;

function openVideoModal(event, title, videoId) {
  event.preventDefault();

  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style["overflow"] = "hidden";
  document.body.style["padding-right"] = `${scrollbarWidth}px`;

  const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
  if (headerNoticeWrap) {
    headerNoticeWrap.style["padding-right"] = `${scrollbarWidth}px`;
  }

  const modal = document.getElementById("VideoModal");
  const heading = document.getElementById("VideoModal__heading");
  heading.innerText = title;

  playVideo(videoId);

  modal.classList.remove("hidden");
  changeOpacity("VideoModal__bg", 100);

  gtag("send", "event", "openVideoModal", title);

  return false;
}

window.openVideoModal = openVideoModal;

function closeVideoModal(event) {
  event.stopPropagation();

  const target = event.target;
  const closeIcon = target.closest("#VideoModal__close");

  if (target.id == "VideoModal__bg" || closeIcon) {
    document.body.style["overflow"] = "visible";
    document.body.style["padding-right"] = "0px";
    const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
    if (headerNoticeWrap) {
      headerNoticeWrap.style["padding-right"] = "0px";
    }

    const modal_bg = document.getElementById("VideoModal__bg");
    modal_bg.style["opacity"] = 0;

    setTimeout(function () {
      document.getElementById("VideoModal").classList.add("hidden");
      const playerDiv = document.getElementById(VideoPlayer.id())

      VideoPlayer.dispose();
      window.VideoPlayer = undefined;
      playerDiv.remove()
    }, 200);
  }

  return false;
}

window.closeVideoModal = closeVideoModal;

function playVideo(videoId) {
  const wrap = document.getElementById("VideoModal__iframe")
  const playerDiv = document.createElement("video-js")
  playerDiv.id = `player_${videoId}`
  wrap.appendChild(playerDiv)

  window.VideoPlayer = videojs(`player_${videoId}`, {
    controls: true,
    autoplay: true,
    preload: 'auto',
    fluid: true,
  });

  VideoPlayer.src({
    type: 'application/x-mpegURL',
    src: `https://customer-45o4nz2sew10rj0s.cloudflarestream.com/${videoId}/manifest/video.m3u8`,
  });

  VideoPlayer.on('ready', function() {
    document.getElementById("VideoModal__spinner").classList.add("hidden");
    document.getElementById("VideoModal__iframe").classList.remove("hidden");

    if (!isMobile) {
      setTimeout(function() {
        let qualityLevels = VideoPlayer.qualityLevels();
        qualityLevels.selectedIndex_ = 0;
        qualityLevels.trigger({ type: 'change', selectedIndex: 0 });
      }, 500)
    }
  });
}

function initVideoModal() {
  const modal = document.getElementById("VideoModal__bg");
  const modal_close = document.getElementById("VideoModal__close");

  if (modal) {
    modal.addEventListener("click", closeVideoModal);
    modal_close.addEventListener("click", closeVideoModal);
  }
}

initVideoModal();